<template>
  <div v-loading="loading">
    <h1>Al·lèrgies</h1>
    <el-table :data="guestsComingWithAllergies">
      <el-table-column label="Nom" prop="name" width="150"/>
      <el-table-column label="Tipus" width="100">
        <template #default="guestScope">
          <el-tag v-if="guestScope.row.type === 'adult'">Adult/a</el-tag>
          <el-tag v-if="guestScope.row.type === 'kid'">Nen/a</el-tag>
          <el-tag v-if="guestScope.row.type === 'baby'">Bebé</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Unitat familiar" width="150">
        <template #default="guestScope">
          <template v-if="getFamilyUnitByGuestId(guestScope.row.id)">
            {{ getFamilyUnitByGuestId(guestScope.row.id).name }}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="allergies" label="Al·lèrgies"></el-table-column>
    </el-table>
  </div>
</template>


<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage} from "element-plus";

export default {
  components: {},
  data() {
    return {
      loading: false,
      familyUnits: [],
    }
  },
  computed: {
    guests() {
      let allGuests = [];

      for (const familyUnit of this.familyUnits) {
        allGuests = allGuests.concat(familyUnit.guests);
      }

      return allGuests;
    },
    guestsComingWithAllergies() {
      return this.guests.filter(guest => guest.coming === true && guest.allergies)
    }
  },
  mounted() {
    this.loadGuests();
  },
  methods: {
    loadGuests() {
      this.loading = true;
      apiAxios.get('/family-units')
        .then(data => {
          this.familyUnits = data.data;
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar els convidats.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFamilyUnitByGuestId(guestId) {
      for (const familyUnit of this.familyUnits) {
        for (const guest of familyUnit.guests) {
          if (guest.id === guestId) {
            return familyUnit;
          }
        }
      }

      return null;
    },
  }
}
</script>

<style lang="scss">
.code-input {
  .el-input {
    width: 75px;
  }
}

.group-input {
  .el-input {
    width: 150px;
  }
}

.link-input {
  .el-form-item__content {
    width: 300px;
  }
}

.el-table .danger-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>