<template>
  <div class="seating" v-loading="loading">
    <div class="seating-title">
      <h1>Seating Plan</h1>
      <span v-if="saving" class="save-status">
        <el-icon>
          <clock/>
        </el-icon>
        Guardant...
      </span>
      <span v-else-if="savingError" class="save-status">
        <el-icon color="red">
          <close-bold/>
        </el-icon>
        Error al guardar
      </span>
      <span v-else-if="pendingChanges" class="save-status">
        <el-icon>
          <clock/>
        </el-icon>
        Hi ha canvis pendents...
      </span>
      <span v-else-if="lastSave" class="save-status">
        <el-icon color="green">
          <check-bold/>
        </el-icon>
        Guardat: {{ formatDate(lastSave) }}
      </span>
    </div>
    <el-row :gutter="16">
      <el-col v-for="table in seatingPlan" :key="table.id" :span="8">
        <el-card>
          <template #header>
            <div class="table-header">
              <el-input v-model="table.name" @change="onChangeTable"></el-input>
              <div class="people">
                <el-icon>
                  <avatar/>
                </el-icon>
                {{table.guests.length}}
              </div>
              <el-button type="danger" circle @click="askDeleteTable(table.id, table.name)">
                <el-icon>
                  <delete/>
                </el-icon>
              </el-button>
            </div>
          </template>
          <seating-plan-table v-model="table.guests" @change="onChangeTable"/>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-button size="large" @click="addTable">Afegir taula</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="8">
        <el-card>
          <template #header>Sense assignar</template>
          <seating-plan-table v-model="pendingGuests" @change="onChangeTable"/>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage, ElMessageBox} from "element-plus";
import SeatingPlanTable from "@/components/SeatingPlanTable.vue";
import {v4 as uuidv4} from 'uuid';
import {Delete, Clock, CloseBold, Select, Avatar} from "@element-plus/icons-vue";

export default {
  components: {
    SeatingPlanTable,
    Delete,
    Clock,
    CloseBold,
    CheckBold: Select,
    Avatar,
  },
  data() {
    return {
      pendingChanges: false,
      saving: false,
      savingError: false,
      lastSave: null,
      saveInterval: null,
      loading: false,
      pendingGuests: [],
      seatingPlan: [],
    }
  },
  mounted() {
    this.init();
    this.saveInterval = setInterval(this.save, 2000)
  },
  unmounted() {
    clearInterval(this.saveInterval);
    this.saveInterval = null;
  },
  methods: {
    init() {
      this.loadSeatingPlan();
    },
    loadSeatingPlan() {
      this.loading = true;
      apiAxios.get('/seating')
        .then(data => {
          this.seatingPlan = data.data;
          this.loadPendingGuests();
        })
        .catch(error => {
          ElMessage.error('Ups, no s\'ha pogut carregar el seating plan.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadPendingGuests() {
      this.loading = true;
      apiAxios.get('/family-units')
        .then(data => {
          let allGuests = [];

          for (const familyUnit of data.data) {
            familyUnit.guests = familyUnit.guests
              .filter(guest => guest.coming !== false)
              .filter(guest => !this.isGuestInSeatingPlan(guest))
              .map(guest => Object.assign({}, guest, {
                familyUnitName: familyUnit.name,
                groupName: familyUnit.group ? familyUnit.group.name : '',
              }))
            allGuests = allGuests.concat(
              familyUnit.guests
            );
          }

          this.pendingGuests = allGuests.map(this.formatGuest);
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar els convidats.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isGuestInSeatingPlan(guest) {
      for (const table of this.seatingPlan) {
        for (const tableGuest of table.guests) {
          if (tableGuest.id === guest.id) {
            return true;
          }
        }
      }

      return false;
    },
    formatGuest(guest) {
      return {
        id: guest.id,
        name: guest.name,
        group: this.formatGuestGroup(guest),
        coming: guest.coming,
      }
    },
    formatGuestGroup(guest) {
      if (guest.groupName !== '') {
        return guest.groupName + ' → ' + guest.familyUnitName;
      }

      return guest.familyUnitName;
    },
    addTable() {
      this.seatingPlan.push(
        {
          id: uuidv4(),
          name: 'Taula nova',
          guests: [],
        }
      );
      this.pendingChanges = true;
    },
    askDeleteTable(id, name) {
      ElMessageBox.confirm('Segur que vols esborrar la taula ' + name + '? Tots els convidats d\'aquesta taula tornaran a la llista per assignar.', 'Esborrar taula', {
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.deleteTable(id);
      }).catch(() => {
      });
    },
    deleteTable(id) {
      let guests = this.seatingPlan.find(t => t.id === id).guests;
      this.pendingGuests = this.pendingGuests.concat(guests);
      this.seatingPlan = this.seatingPlan.filter(table => table.id !== id);
      this.pendingChanges = true;
    },
    async save() {
      if (!this.pendingChanges) {
        return;
      }

      if (this.saving) {
        return;
      }

      this.pendingChanges = false;
      this.saving = true;
      this.savingError = false;

      apiAxios.post('/seating', this.seatingPlan)
        .then(() => {
          this.lastSave = new Date();
        })
        .catch(() => {
          this.pendingChanges = true;
          this.savingError = true;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    formatDate(date) {
      return date.toLocaleString('ca-es', {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: '2-digit',
        second: '2-digit'
      })
    },
    onChangeTable() {
      this.pendingChanges = true;
    },
  }
}
</script>

<style scoped lang="scss">
.seating {
  .seating-title {
    align-items: baseline;
    display: inline-flex;
    gap: 1em;

    .save-status {
      gap: 0.2em;
      display: inline-flex;
      align-items: center;
      color: #909399;
    }
  }

  .el-col {
    margin-bottom: 16px;
  }

  .table-header {
    display: flex;
    align-items: center;
    gap: 16px;

    .people {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 14px;
    }
  }
}
</style>