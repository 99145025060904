<template>
  <el-col :span="6">
    <div class="statistic-card">
      <el-statistic :value="value">
        <template #title>
          <div style="display: inline-flex; align-items: center">
            {{title}}
            <el-tooltip
              effect="dark"
              :content="info"
              placement="top"
            >
              <el-icon style="margin-left: 4px" :size="12">
                <InfoFilled/>
              </el-icon>
            </el-tooltip>
          </div>
        </template>
      </el-statistic>
      <div v-if="guests" class="statistic-footer">
          <guests-percentages-bar :guests="guests"></guests-percentages-bar>
      </div>
    </div>
  </el-col>
</template>

<script>
import {InfoFilled} from "@element-plus/icons-vue";
import GuestsPercentagesBar from "@/components/GuestsPercentagesBar.vue";

export default {
  components: {GuestsPercentagesBar, InfoFilled},
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    guests: {
      type: Object,
      required: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}
</style>