import {store} from "@/store";
import router from "@/router/router";

const rejectUnauthenticatedResponse = (error) => {
    if (error.response.status === 401) {
        store.dispatch('clearAuth');
        router.push('/login');
        throw error;
    }

    throw error;
}

export default (instance) =>
    instance.interceptors.response.use(undefined, rejectUnauthenticatedResponse)
