import {createStore} from 'vuex';

export const store = createStore({
    state() {
        return {
            auth: localStorage.getItem('auth'),
        }
    },

    actions: {
        setAuth({commit}, payload) {
            commit("setAuth", payload);
        },
        clearAuth({commit}, payload) {
            commit("clearAuth", payload);
        },
    },

    mutations: {
        setAuth(state, auth) {
            state.auth = auth;
            localStorage.setItem('auth', state.auth);
        },
        clearAuth(state) {
            state.auth = null;
            localStorage.removeItem('auth');
        },
    }
});