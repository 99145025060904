<template>
  <div class="guests-percentages-bar">
    <div class="accepted-guests" :style="{width: acceptedPercentage + '%'}"></div>
    <div class="rejected-guests" :style="{left: acceptedPercentage + '%', width: rejectedPercentage + '%'}"></div>
  </div>
</template>

<script>
export default {
  props: {
    guests: {
      type: Object,
      required: true,
    }
  },
  computed: {
    acceptedPercentage() {
      if (this.guests.total === 0) {
        return 0;
      }

      return (this.guests.accepted / this.guests.total) * 100;
    },
    rejectedPercentage() {
      if (this.guests.total === 0) {
        return 0;
      }

      return (this.guests.rejected / this.guests.total) * 100;
    },
  }
}
</script>

<style lang="scss" scoped>
.guests-percentages-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  background: var(--el-fill-color-dark);
  border-radius: 3px;
  overflow: hidden;

  .accepted-guests {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 50px;
    background: var(--el-color-success);
  }

  .rejected-guests {
    position: absolute;
    top: 0;
    left: 50px;
    height: 10px;
    width: 30px;
    background: var(--el-color-danger);
  }
}
</style>