<template>
  <el-form label-width="120px">
    <el-form-item label="ID">
      <el-input v-if="mode === 'edit'" v-model="localGroup.id" disabled/>
      <el-input v-else value="(Conegut després de guardar)" disabled/>
    </el-form-item>
    <el-form-item label="Nom">
      <el-input v-model="localGroup.name"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="save()">Guardar</el-button>
      <el-button @click="cancel()">Cancel·lar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage} from "element-plus";

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localGroup: Object.assign({}, this.group),
      loading: false,
    }
  },
  methods: {
    save() {
      if (this.mode === 'edit') {
        this.saveEdit();
      } else {
        this.saveNew();
      }
    },
    saveEdit() {
      this.loading = true;
      apiAxios.put('/groups/' + this.localGroup.id, this.localGroup)
        .then(() => {
          ElMessage.success('S\'ha guardat el grup');
          this.$emit('save');
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut guardar el grup.')
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveNew() {
      this.loading = true;
      apiAxios.post('/groups', this.localGroup)
        .then(() => {
          ElMessage.success('S\'ha guardat el grup');
          this.$emit('save');
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut guardar el grup.')
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style scoped>

</style>