<template>
  <div class="login-container">
    <el-card class="login-card">
      <template #header>
        <div class="card-header">
          <h3>Inicia sessió</h3>
        </div>
      </template>
      <el-form :model="loginData" label-position="top">
        <el-form-item label="Email">
          <el-input v-model="loginData.email"></el-input>
        </el-form-item>
        <el-form-item label="Contrasenya">
          <el-input v-model="loginData.password" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submitForm">Inicia sessió</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {apiPublicAxios} from "@/service/apiClient";
import {store} from "@/store";
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      loginData: {
        email: '',
        password: ''
      },
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      apiPublicAxios
        .post('/login', this.loginData)
        .then(data => {
          store.commit('setAuth', data.data.token)
          this.$router.push('/');
        })
        .catch(error => {
          ElMessage.error('Dades incorrectes');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.login-card {
  width: 400px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 20px;
}
</style>