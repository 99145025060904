<template>
  <div v-loading="loading">
    <h1>Fotos</h1>

    <div class="gallery">
      <div v-for="image in images" :key="image.id" class="photo-container">
        <a :href="image.imageUrl" target="_blank">
          <el-image :src="image.thumbnailUrl" fit="cover"/>
        </a>

        <el-button circle type="danger" @click="deleteImage(image.id)">
          <template #icon>
            <el-icon>
              <delete/>
            </el-icon>
          </template>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {Delete} from '@element-plus/icons-vue'

export default {
  components: {Delete},
  data() {
    return {
      loading: false,
      images: [],
    }
  },
  mounted() {
    this.loadGallery();
  },
  methods: {
    loadGallery() {
      this.loading = true;

      apiAxios.get('/gallery/all')
        .then((data) => {
          this.images = data.data.images;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteImage(id) {
      this.loading = true;

      apiAxios.delete('/gallery/' + id)
        .then(() => {
          this.loadGallery();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped lang="scss">
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  .photo-container {
    position: relative;
    border: 1px solid #909399;
    height: 150px;
    padding: 16px;
    background: white;

    .el-image {
      padding: 0;
      width: 150px;
      height: 150px;
    }

    .el-button {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }
}
</style>