<template>
  <draggable
    v-model="localValue"
    group="guests"
    item-key="id"
    handle=".drag"
    v-bind="dragOptions"
    @end="onEndDragging"
  >
    <template #item="{ element }">
      <div class="item">
        <div class="drag">
          <el-icon>
            <sort/>
          </el-icon>
        </div>
        <div class="text" :class="{danger: element.coming === false, alert: element.coming === null}">
          {{ element.name }}
          <span class="group">{{ element.group }}</span>
        </div>
        <el-tooltip :content="element.name + ' encara no ha indicat si vindrà o no.'">
          <el-icon v-if="element.coming === null" color="orange" class="warning-icon">
            <warning/>
          </el-icon>
        </el-tooltip>
        <el-tooltip :content="element.name + ' no vindrà!'">
          <el-icon v-if="element.coming === false" color="red" class="warning-icon">
            <circle-close-filled/>
          </el-icon>
        </el-tooltip>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import {Sort, Warning, CircleCloseFilled} from "@element-plus/icons-vue";

export default {
  components: {Sort, Warning, CircleCloseFilled, draggable},
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "guests",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    }
  },
  methods: {
    onEndDragging() {
      this.$emit('change');
    },
  },
  watch: {
    modelValue(v) {
      this.localValue = v;
    },
    localValue(v) {
      this.$emit('update:modelValue', v);
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  background: white;

  .text {
    font-size: 14px;
    margin-left: 1em;

    .group {
      color: #909399;
      font-size: 0.8em;
    }

    &.danger {
      color: #c45656;
      font-weight: bold;

      .group {
        color: #F56C6C;
        font-weight: normal;
      }
    }

    &.alert {
      color: #b88230;
      font-weight: bold;

      .group {
        color: #E6A23C;
        font-weight: normal;
      }
    }
  }

  .drag {
    cursor: move;
  }

  .warning-icon {
    margin-left: 0.5em;
  }
}
</style>