<template>
  <el-form v-if="localFamilyUnit" v-loading="loading" label-width="120px">
    <el-form-item label="ID">
      <el-input v-model="localFamilyUnit.id" disabled/>
    </el-form-item>
    <el-form-item label="Nom">
      <el-input v-model="localFamilyUnit.name"/>
    </el-form-item>
    <el-form-item label="Grup">
      <el-select v-model="localFamilyUnit.groupId">
        <el-option
          label="(Cap)"
          :value="null"
        ></el-option>
        <el-option
          v-for="group in groups"
          :key="group.id"
          :label="group.name"
          :value="group.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Codi d'invitació">
      <el-input v-model="localFamilyUnit.code"/>
    </el-form-item>
    <el-form-item label="Idioma">
      <el-radio-group v-model="localFamilyUnit.language">
        <el-radio label="ca">Català</el-radio>
        <el-radio label="es">Castellà</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="save()">Guardar</el-button>
      <el-button @click="cancel()">Cancel·lar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage} from "element-plus";

export default {
  props: {
    familyUnit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFamilyUnit: null,
      loading: false,
      groups: [],
    }
  },
  mounted() {
    this.loadLocalFamilyUnit();
    this.loadGroups();
  },
  methods: {
    loadLocalFamilyUnit() {
      this.localFamilyUnit = Object.assign({}, this.familyUnit);
      this.localFamilyUnit.groupId = this.familyUnit.group ? this.familyUnit.group.id : null;
    },
    loadGroups() {
      this.loading = true;
      apiAxios.get('/groups')
        .then(data => {
          this.groups = data.data;
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar els grups.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.saveEdit();
    },
    saveEdit() {
      this.loading = true;
      apiAxios.put('/family-units/' + this.localFamilyUnit.id, this.localFamilyUnit)
        .then(() => {
          ElMessage.success('S\'ha guardat la unitat familiar');
          this.$emit('save');
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut guardar la unitat familiar.')
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style scoped>

</style>