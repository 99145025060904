<template>
  <div class="statistics-page" v-loading="loading">
    <div class="section">
      <h1>Convidats</h1>

      <el-form-item label="Visualització">
        <el-radio-group v-model="selectedStatistics">
          <el-radio-button label="all">Tots</el-radio-button>
          <el-radio-button label="adults">Adults</el-radio-button>
          <el-radio-button label="kids">Nens</el-radio-button>
          <el-radio-button label="babies">Bebés</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-row :gutter="16">
        <statistic-card title="Convidats" :value="statistics[selectedStatistics].total" :guests="statistics[selectedStatistics]"
                        info="Número total de convidats que hi ha a la llista"/>
        <statistic-card title="Confirmats" :value="statistics[selectedStatistics].accepted"
                        info="Número de convidats que han confirmat la seva assistència"/>
        <statistic-card title="Rebutjats" :value="statistics[selectedStatistics].rejected"
                        info="Número de convidats que no vindran a la celebració"/>
        <statistic-card title="Pendents" :value="statistics[selectedStatistics].pending"
                        info="Número de convidats que falten per confirmar la seva assistència"/>
      </el-row>
    </div>
  </div>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage} from "element-plus";
import StatisticCard from "@/components/StatisticCard.vue";

export default {
  components: {StatisticCard},
  data() {
    return {
      selectedStatistics: 'all',
      statistics: {
        all: {
          total: 0,
          accepted: 0,
          rejected: 0,
          pending: 0,
        },
        adults: {
          total: 0,
          accepted: 0,
          rejected: 0,
          pending: 0,
        },
        kids: {
          total: 0,
          accepted: 0,
          rejected: 0,
          pending: 0,
        },
        babies: {
          total: 0,
          accepted: 0,
          rejected: 0,
          pending: 0,
        },
      },
      loading: false,
    }
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      this.loading = true;
      apiAxios.get('/statistics')
        .then(data => {
          this.statistics = data.data;
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar les estadístiques.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-page {
  .section {
    +.section {
      margin-top: 4em;
    }
  }
}
</style>