import router from '@/router/router';
import {store} from '@/store';

const getAuthToken = () => localStorage.getItem('auth')

const addAuthTokenHeader = (config) => {
    const token = getAuthToken()

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }

    store.dispatch('clearAuth')
    router.push('/login')

    return config;
}

export default (instance) => instance.interceptors.request.use(addAuthTokenHeader, undefined)
