<template>
  <el-container>
    <el-aside v-if="showAside">
      <el-menu :router="true">
        <el-menu-item index="1" :route="{name: 'main'}">
          <el-icon>
            <histogram/>
          </el-icon>
          <span>Pàgina Principal</span>
        </el-menu-item>
        <el-menu-item index="2" :route="{name: 'groups'}">
          <el-icon>
            <avatar/>
          </el-icon>
          <span>Grups</span>
        </el-menu-item>
        <el-menu-item index="3" :route="{name: 'guests'}">
          <el-icon>
            <list/>
          </el-icon>
          <span>Convidats</span>
        </el-menu-item>
        <el-menu-item index="4" :route="{name: 'allergies'}">
          <el-icon>
            <food/>
          </el-icon>
          <span>Al·lèrgies</span>
        </el-menu-item>
        <el-menu-item index="5" :route="{name: 'notes'}">
          <el-icon>
            <document/>
          </el-icon>
          <span>Notes de Convidats</span>
        </el-menu-item>
        <el-menu-item index="6" :route="{name: 'seating'}">
          <el-icon>
            <guide/>
          </el-icon>
          <span>Seating Plan</span>
        </el-menu-item>
        <el-menu-item index="7" :route="{name: 'gallery'}">
          <el-icon>
            <picture-icon/>
          </el-icon>
          <span>Fotos</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view/>
    </el-main>
  </el-container>
</template>

<script>

import {Histogram, Avatar, List, Food, Document, Guide, Picture as PictureIcon} from '@element-plus/icons-vue'

export default {
  name: 'App',
  components: {Histogram, Avatar, List, Food, Document, Guide, PictureIcon},
  computed: {
    showAside() {
      return this.$route.name !== 'login';
    },
  },
  methods: {}
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: sans-serif;

  #app {
    min-height: 100vh;

    .el-container {
      min-height: 100vh;

      .el-aside {
        .el-menu {
          min-height: 100%;
        }
      }

      .el-main {
        background-color: var(--el-fill-color);
      }
    }
  }
}
</style>
