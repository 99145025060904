<template>
  <div v-loading="loading">
    <h1>Grups</h1>
    <el-table :data="groups">
      <el-table-column prop="name" label="Nom"/>
      <el-table-column label="Accions">
        <template #default="scope">
          <el-button size="small" @click="showEditGroup(scope.row)">Editar</el-button>
          <el-button size="small" type="danger" @click="showDeleteGroup(scope.row)">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" size="large" style="margin-top: 1em;" @click="showNewGroup">Grup nou</el-button>

    <el-dialog
      v-model="editingGroupVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      title="Editar grup"
    >
      <group-form :group="editingGroup" :mode="editingGroupMode" @cancel="closeEditGroup" @save="onGroupEdit"/>
    </el-dialog>
  </div>
</template>

<script>
import GroupForm from "@/components/GroupForm.vue";
import {apiAxios} from "@/service/apiClient";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
  components: {GroupForm},
  data() {
    return {
      loading: false,
      editingGroupVisible: false,
      editingGroup: null,
      editingGroupMode: null,
      deletingGroup: null,
      groups: []
    }
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    loadGroups() {
      this.loading = true;
      apiAxios.get('/groups')
        .then(data => {
          this.groups = data.data;
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar els grups.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNewGroup() {
      this.editingGroupMode = 'new';
      this.editingGroupVisible = true;
      this.editingGroup = {
        id: null,
        name: '',
      }
    },
    showEditGroup(group) {
      this.editingGroupMode = 'edit';
      this.editingGroupVisible = true;
      this.editingGroup = group;
    },
    closeEditGroup() {
      this.editingGroupVisible = false;
    },
    showDeleteGroup(group) {
      ElMessageBox.confirm(
        'Tots els convidats que pertanyin a "' + group.name + '" s\'eliminaran. Continuar?',
        'Atenció',
        {
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          type: 'warning',
        }
      )
        .then(() => {
          this.deleteGroup(group);
        })
        .catch(() => {

        })
    },
    deleteGroup(group) {
      this.loading = true;
      apiAxios.delete('/groups/' + group.id)
        .then(() => {
          this.loadGroups();
          ElMessage({
            type: 'success',
            message: 'Grup eliminat',
          })
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut eliminar el grup.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onGroupEdit() {
      this.closeEditGroup();
      this.loadGroups();
    }
  }
}
</script>

<style scoped>

</style>