<template>
  <el-form label-width="120px">
    <el-form-item label="ID">
      <el-input v-if="mode === 'edit'" v-model="localGuest.id" disabled/>
      <el-input v-else value="(Conegut després de guardar)" disabled/>
    </el-form-item>
    <el-form-item label="Nom">
      <el-input v-model="localGuest.name"/>
    </el-form-item>
    <el-form-item label="Tipus">
      <el-radio-group v-model="localGuest.type">
        <el-radio label="adult">Adult/a</el-radio>
        <el-radio label="kid">Nen/a</el-radio>
        <el-radio label="baby">Bebé</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="mode === 'edit'" label="Assistirà">
      <el-radio-group v-model="localGuest.coming">
        <el-radio :label="true">Sí</el-radio>
        <el-radio :label="false">No</el-radio>
        <el-radio :label="null">No ho sap</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="mode === 'edit'" label="Nom complet">
      <el-input v-model="localGuest.fullName"/>
    </el-form-item>
    <el-form-item v-if="mode === 'edit'" label="Al·lèrgies">
      <el-input v-model="localGuest.allergies"/>
    </el-form-item>
    <el-form-item v-if="mode === 'edit'" label="Nota">
      <el-input v-model="localGuest.note"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="save()">Guardar</el-button>
      <el-button @click="cancel()">Cancel·lar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage} from "element-plus";

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localGuest: Object.assign({}, this.guest),
      loading: false,
    }
  },
  methods: {
    save() {
      if (this.mode === 'edit') {
        this.saveEdit();
      } else {
        this.saveNew();
      }
    },
    saveEdit() {
      this.loading = true;
      apiAxios.put('/guests/' + this.localGuest.id, this.localGuest)
        .then(() => {
          ElMessage.success('S\'ha guardat el convidat');
          this.$emit('save');
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut guardar el convidat.')
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveNew() {
      this.loading = true;
      apiAxios.post('/guests', this.localGuest)
        .then(() => {
          ElMessage.success('S\'ha guardat el convidat');
          this.$emit('save');
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut guardar el convidat.')
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style scoped>

</style>