<template>
  <div v-loading="loading">
    <h1>Convidats</h1>

    <el-form-item label="Visualització">
      <el-radio-group v-model="view">
        <el-radio-button label="Unitats Familiars"/>
        <el-radio-button label="Convidats"/>
      </el-radio-group>
    </el-form-item>

    <template v-if="view === 'Unitats Familiars'">
      <el-table :data="familyUnits">
        <el-table-column prop="name" label="Unitat familiar" width="200"/>
        <el-table-column label="Convidats">
          <template #default="scope">
            <div>
              <el-form :inline="true">
                <el-form-item class="group-input" label="Grup">
                  <el-input size="small" :value="scope.row.group ? scope.row.group.name : '(Cap)'" readonly/>
                </el-form-item>
                <el-form-item class="code-input" label="Codi d'invitació">
                  <el-input size="small" v-model="scope.row.code" readonly/>
                </el-form-item>
                <el-form-item class="link-input" label="Link d'invitació">
                  <el-input size="small" :value="invitationLink(scope.row)" readonly>
                    <template #append>
                      <el-button size="small" @click="copyToClipboard(invitationLink(scope.row))">
                        <el-icon>
                          <document-copy/>
                        </el-icon>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" @click="showNewGuest(scope.row.id)">Afegir convidat</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" @click="showEditFamilyUnit(scope.row)">Editar</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" type="danger" @click="showDeleteFamilyUnit(scope.row)">Eliminar</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-table v-if="scope.row.guests.length > 0" :data="scope.row.guests" :row-class-name="guestRowClassName"
                      size="small">
              <el-table-column prop="name" label="Nom"></el-table-column>
              <el-table-column label="Tipus">
                <template #default="guestScope">
                  <el-tag v-if="guestScope.row.type === 'adult'">Adult/a</el-tag>
                  <el-tag v-if="guestScope.row.type === 'kid'">Nen/a</el-tag>
                  <el-tag v-if="guestScope.row.type === 'baby'">Bebé</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="fullName" label="Nom complet"></el-table-column>
              <el-table-column prop="allergies" label="Al·lèrgies"></el-table-column>
              <el-table-column label="Accions">
                <template #default="guestScope">
                  <el-button size="small" @click="showEditGuest(guestScope.row)">Editar</el-button>
                  <el-button size="small" type="danger" @click="showDeleteGuest(guestScope.row)">Eliminar</el-button>
                </template>
              </el-table-column>
            </el-table>
            <i v-else>No hi ha convidats en aquesta unitat familiar</i>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" size="large" style="margin-top: 1em;" @click="showNewFamilyUnit">
        Nova unitat familiar
      </el-button>
    </template>
    <template v-else>
      <div class="flex">
        <el-form-item label="Assistència" size="small">
          <el-radio-group v-model="coming">
            <el-radio-button label="Tots" value="all"/>
            <el-radio-button label="Sí" value="yes"/>
            <el-radio-button label="No" value="no"/>
            <el-radio-button label="Pendents" value="pending"/>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Tipus" size="small">
          <el-radio-group v-model="type">
            <el-radio-button label="Tots" value="all"/>
            <el-radio-button label="Adult" value="adult"/>
            <el-radio-button label="Nen" value="kid"/>
            <el-radio-button label="Bebé" value="baby"/>
          </el-radio-group>
        </el-form-item>
      </div>

      <el-table :data="filteredGuests" :row-class-name="guestRowClassName">
        <el-table-column label="Nom" prop="name"/>
        <el-table-column label="Tipus">
          <template #default="guestScope">
            <el-tag v-if="guestScope.row.type === 'adult'">Adult/a</el-tag>
            <el-tag v-if="guestScope.row.type === 'kid'">Nen/a</el-tag>
            <el-tag v-if="guestScope.row.type === 'baby'">Bebé</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Unitat familiar">
          <template #default="guestScope">
            <template v-if="getFamilyUnitByGuestId(guestScope.row.id)">
              {{ getFamilyUnitByGuestId(guestScope.row.id).name }}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Grup">
          <template #default="guestScope">
            <template v-if="getGroupByGuestId(guestScope.row.id)">
              {{ getGroupByGuestId(guestScope.row.id).name }}
            </template>
            <template v-else>
              (Cap)
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="fullName" label="Nom complet"></el-table-column>
        <el-table-column prop="allergies" label="Al·lèrgies"></el-table-column>
        <el-table-column label="Idioma">
          <template #default="guestScope">
            <template v-if="getFamilyUnitByGuestId(guestScope.row.id)">
              {{ getLanguageName(getFamilyUnitByGuestId(guestScope.row.id).language) }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <el-dialog
      v-model="editingGuestVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      title="Editar convidat"
    >
      <guest-form :guest="editingGuest" :mode="editingGuestMode" @cancel="closeEditGuest" @save="onGuestEdit"/>
    </el-dialog>
    <el-dialog
      v-model="editingFamilyUnitVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      title="Editar unitat familiar"
    >
      <family-unit-form :family-unit="editingFamilyUnit" @cancel="closeEditFamilyUnit"
                        @save="onFamilyUnitEdit"/>
    </el-dialog>
    <el-dialog
      v-model="newFamilyUnitVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      title="Nova unitat familiar"
    >
      <new-family-unit-form :family-unit="editingFamilyUnit" @cancel="closeNewFamilyUnit" @save="onFamilyUnitEdit"/>
    </el-dialog>
  </div>
</template>


<script>
import {apiAxios} from "@/service/apiClient";
import {ElMessage, ElMessageBox} from "element-plus";
import {DocumentCopy} from '@element-plus/icons-vue';
import GuestForm from "@/components/GuestForm.vue";
import FamilyUnitForm from "@/components/FamilyUnitForm.vue";
import NewFamilyUnitForm from "@/components/NewFamilyUnitForm.vue";

export default {
  components: {NewFamilyUnitForm, FamilyUnitForm, GuestForm, DocumentCopy},
  data() {
    return {
      loading: false,
      editingGuestVisible: false,
      editingGuest: null,
      editingGuestMode: null,
      deletingGuest: null,
      editingFamilyUnitVisible: false,
      editingFamilyUnit: null,
      deletingFamilyUnit: null,
      newFamilyUnitVisible: false,
      familyUnits: [],
      view: 'Unitats Familiars',
      coming: 'all',
      type: 'all',
    }
  },
  computed: {
    guests() {
      let allGuests = [];

      for (const familyUnit of this.familyUnits) {
        allGuests = allGuests.concat(familyUnit.guests);
      }

      return allGuests;
    },
    filteredGuests() {
      let guests = this.guests;

      if (this.type !== 'all') {
        guests = guests.filter(guest => guest.type === this.type);
      }

      if (this.coming === 'yes') {
        guests = guests.filter(guest => guest.coming === true);
      }

      if (this.coming === 'no') {
        guests = guests.filter(guest => guest.coming === false);
      }

      if (this.coming === 'pending') {
        guests = guests.filter(guest => guest.coming === null);
      }

      return guests;
    }
  },
  mounted() {
    this.loadGuests();
  },
  methods: {
    loadGuests() {
      this.loading = true;
      apiAxios.get('/family-units')
        .then(data => {
          this.familyUnits = data.data;
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut carregar els convidats.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNewGuest(familyUnitId) {
      this.editingGuestMode = 'new';
      this.editingGuestVisible = true;
      this.editingGuest = {
        id: null,
        name: '',
        type: 'adult',
        fullName: '',
        allergies: '',
        note: '',
        familyUnitId: familyUnitId,
      }
    },
    showEditGuest(Guest) {
      this.editingGuestMode = 'edit';
      this.editingGuestVisible = true;
      this.editingGuest = Guest;
    },
    closeEditGuest() {
      this.editingGuestVisible = false;
    },
    showDeleteGuest(guest) {
      ElMessageBox.confirm(
        'Segur que vols eliminar a "' + guest.name + '" de la llista de convidats?',
        'Atenció',
        {
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          type: 'warning',
        }
      )
        .then(() => {
          this.deleteGuest(guest);
        })
        .catch(() => {

        })
    },
    deleteGuest(guest) {
      this.loading = true;
      apiAxios.delete('/guests/' + guest.id)
        .then(() => {
          this.loadGuests();
          ElMessage({
            type: 'success',
            message: 'Convidat eliminat',
          })
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'han pogut eliminar el convidat.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onGuestEdit() {
      this.closeEditGuest();
      this.loadGuests();
    },
    showEditFamilyUnit(FamilyUnit) {
      this.editingFamilyUnitVisible = true;
      this.editingFamilyUnit = FamilyUnit;
    },
    closeEditFamilyUnit() {
      this.editingFamilyUnitVisible = false;
    },
    showDeleteFamilyUnit(familyUnit) {
      ElMessageBox.confirm(
        'Segur que vols eliminar la unitat familiar "' + familyUnit.name + '" de la llista de convidats? S\'eliminaran també tots els convidats que hi hagi dins.',
        'Atenció',
        {
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          type: 'warning',
        }
      )
        .then(() => {
          this.deleteFamilyUnit(familyUnit);
        })
        .catch(() => {

        })
    },
    deleteFamilyUnit(familyUnit) {
      this.loading = true;
      apiAxios.delete('/family-units/' + familyUnit.id)
        .then(() => {
          this.loadGuests();
          ElMessage({
            type: 'success',
            message: 'Unitat familiar eliminada',
          })
        })
        .catch((error) => {
          ElMessage.error('Ups, no s\'ha pogut eliminar la unitat familiar.');
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNewFamilyUnit() {
      this.newFamilyUnitVisible = true;
      this.editingFamilyUnit = {
        id: null,
        name: '',
        groupId: null,
        language: 'ca',
        guests: [
          {
            name: '',
            type: 'adult'
          }
        ]
      }
    },
    closeNewFamilyUnit() {
      this.newFamilyUnitVisible = false;
    },
    onFamilyUnitEdit() {
      this.closeEditFamilyUnit();
      this.closeNewFamilyUnit();
      this.loadGuests();
    },
    invitationLink(familyUnit) {
      return process.env.VUE_APP_BASE_URL + '?i=' + familyUnit.code
    },
    guestRowClassName(row) {
      if (row.row.coming === true) {
        return 'success-row';
      }
      if (row.row.coming === false) {
        return 'danger-row';
      }

      return 'default-row';
    },
    getFamilyUnitByGuestId(guestId) {
      for (const familyUnit of this.familyUnits) {
        for (const guest of familyUnit.guests) {
          if (guest.id === guestId) {
            return familyUnit;
          }
        }
      }

      return null;
    },
    getGroupByGuestId(guestId) {
      for (const familyUnit of this.familyUnits) {
        for (const guest of familyUnit.guests) {
          if (guest.id === guestId) {
            return familyUnit.group;
          }
        }
      }

      return null;
    },
    getLanguageName(code) {
      return code === 'es' ? 'Castellà' : 'Català'
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          ElMessage.success('Text copiat')
        })
        .catch(() => {
          ElMessage.error('No s\'ha pogut copiar')
        })
    },
  }
}
</script>

<style lang="scss">
.code-input {
  .el-input {
    width: 75px;
  }
}

.group-input {
  .el-input {
    width: 150px;
  }
}

.link-input {
  .el-form-item__content {
    width: 300px;
  }
}

.el-table .danger-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}

.flex {
  display: flex;
  gap: 2em;
}
</style>