import {createRouter, createWebHistory} from "vue-router";
import MainPage from "@/pages/MainPage.vue";
import GroupsPage from "@/pages/GroupsPage.vue";
import GuestsPage from "@/pages/GuestsPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import AllergiesPage from "@/pages/AllergiesPage.vue";
import NotesPage from "@/pages/NotesPage.vue";
import SeatingPlanPage from "@/pages/SeatingPlanPage.vue";
import GalleryPage from "@/pages/GalleryPage.vue";

const routes = [
    {path: '/', name: 'main', component: MainPage},
    {path: '/groups', name: 'groups', component: GroupsPage},
    {path: '/guests', name: 'guests', component: GuestsPage},
    {path: '/allergies', name: 'allergies', component: AllergiesPage},
    {path: '/notes', name: 'notes', component: NotesPage},
    {path: '/seating', name: 'seating', component: SeatingPlanPage},
    {path: '/gallery', name: 'gallery', component: GalleryPage},
    {path: '/login', name: 'login', component: LoginPage},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

export default router